import React from 'react'
import classes from './about.module.css'

const about = () => {
  return (
    <div className={classes.main}>
      <h1>About Us</h1>
      <div className={classes.box}>
      <p>At IITude, we are more than just an educational consultancy – we are a passionate community of accomplished individuals dedicated to nurturing the potential of aspiring students.
 Founded by students of the prestigious IIT Kharagpur, our initiative was born out of a shared vision: to empower students in their pursuit of academic success in competitive exams such as IIT-JEE, NTSE, KVPY, BITSAT, and various Olympiads.
Our mission is clear – to provide unwavering support and guidance to students on their journey towards achieving their academic dreams.
 We understand the challenges that come with preparing for highly competitive exams.We offer two key services to address your challenges effectively: one-on-one mentoring for personalized guidance and doubt-solving to ensure you never feel stuck.
 If you are preparing for IIT-JEE or any competitive exam, consult an IITIAN and get guidance now.</p>
      </div>
    </div>
  )
}

export default about

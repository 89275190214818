import React, { useRef } from "react";
import { Link } from "react-router-dom";
import classes from "./signUp.module.css";
import { useState } from "react";
import { TailSpin } from "react-loading-icons";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar"

import Snackbar from "@mui/material/Snackbar";
import Api from "../API/Api"
import Alert from "@mui/material/Alert";


const FormArea = (props) => {
  const [fieldType, setFieldType] = useState("password");
  const toggleVisibility = (e) => {
    console.log(e);
    fieldType === "text" ? setFieldType("password") : setFieldType("text");
  };
  return (
    <div className={classes.container}>
     
          <form
            className={classes.card}
            onSubmit={props.handleSubmit}
            target="_blank"
            ref={props.formElement}
          >
            <h2 className={classes.card_title}>SIGN UP</h2>
              
               
                <input
                  type="text"
                  name="firstName"
                  required="true"
                  placeholder="First Name"
                />
           
            
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                />
                
                <input
                  type="email"
                  required="true"
                  name="email"
                  placeholder="Email ID"
                />
                <div className={classes.flexDiv}>
               
                <input
                  type="number"
                  pattern="[0-9]{10}"
                  required="true"
                  name="phone"
                  placeholder="mobile No (WhatsApp)"
                />
               
                <select name="standard" placeholder="Class">
                  <option hidden>Class</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </select>
                </div>
          
                <input
                  type="text"
                  required="true"
                  name="college"
                  placeholder="College"
                />
    
                <input
                  placeholder="City"
                  type="text"
                  required="true"
                  name="city"
                />
              
          
                <input
                  type="text"
                  required="true"
                  name="state"
                  placeholder="State"
                />

               
                  <input
                    type={fieldType}
                    required="true"
                    name="password"
                    placeholder="Password"
                  />
                  <h6
                    style={{
                      right: -15,
                      display: "inline",
                      cursor: "pointer",
                      transform: "translateX(-130%)",
                      color: "#f542d1",
                      position: "sticky",
                    }}
                    onClick={(e) => toggleVisibility(e)}
                  >
                    <span className="material-symbols-outlined">
                      {fieldType === "password"
                        ? "visibility"
                        : "visibility_off"}
                    </span>
                  </h6>
                
            <button
              type="submit"
              onClick={() => props.setShow(true)}
            >
              {props.loading ? (
                <span style={{ marginRight: "9px", marginTop: "5px" }}>
                  <TailSpin width="20" height="12" />
                </span>
              ) : (
                ""
              )}
              <span
                style={{ background: "none" }}
              >
                Sign Up
              </span>
            </button>
            <h3 className={classes.form__redirect}>
              ALREADY A MEMBER?{" "}
              <span style={{background:"white",
                              padding:"0.5rem",
                              borderRadius:"20%",
                              }}>
                <Link to="/SignIn">SIGN IN</Link>
              </span>
            </h3>
          </form>
       </div>
  );
};

const SignUp = (props) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [isError, setIsError] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  const formElement = useRef(null);

  const additionalData = {
    sent: new Date().toISOString(),
  };

  // const { handleSubmit, status, message,isShow } = useForm({
  //   form: formElement.current,
  //   additionalData
  // })

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = Array.from(formElement.current)
      .filter((input) => input.name)
      .reduce(
        (obj, input) => Object.assign(obj, { [input.name]: input.value }),
        {}
      );

    // if (additionalData) {
    //   Object.assign(data, additionalData);
    // }

    console.log(data);

    setLoading(true);

    Api.post(`/api/users/register`, data)
      .then((response) => {
        console.log(response)
        if (response.error) {
          setError(response.error)
          throw new Error(response.error);
        }

        setLoading(false);
        //  setError(false)

        setOpen(true);
        setIsError(false);
        setError("Signup Successful");

        navigate("/SignIn", { state: { replace: "true" } });

        console.log("Success");

        return response.json();
      })
      .catch((err) => {
        setLoading(false);

        // setError(err.response.data.message);

        setIsError(true);
        // setError(err.message)
        setOpen(true);
        // navigate('/SignUp',{message:true})
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
        <div>
          <Navbar />

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={error}
          >
            <Alert
              onClose={handleClose}
              severity={isError ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>

          <FormArea
            handleSubmit={handleSubmit}
            formElement={formElement}
            setShow={setShow}
            loading={loading}
          />
        </div>
  );
};

export default SignUp;


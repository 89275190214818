import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Plans from "./pages/Plans"
import MainHome from './MainHome'
import Signup from './pages/signUp'
import SignIn from './pages/signIn'
import OurTeam from "./pages/ourTeam"
import Profile from "./pages/Profile"
import Dashboard from "./pages/Dashboard"
import Refund from "./pages/Refund";
import Private from "./pages/Private"
import TnC from "./pages/TnC"
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainHome />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="Signup" element={<Signup/>} />
        <Route path="SignIn" element={<SignIn/>} />
        <Route path="OurTeam" element={<OurTeam/>} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/private-policy" element={<Private/>} />
        <Route path="terms-and-conditions" element={<TnC/>}/>
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react'
import classes from './footer.module.css'
import { BsInstagram } from 'react-icons/bs'
import { BsFacebook } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const footer = () => {
  return (
    <div className={classes.totalFooter}>
<div className={classes.footDiv}>
      <div className={classes.leftFoot}>
           <h1>IITude</h1>
           <p>
           At IITude, we are more than just an educational consultancy – we are a passionate community of accomplished individuals dedicated to nurturing the potential of aspiring students.<br />
 We understand the challenges that come with preparing for highly competitive exams.We offer two key services to address your challenges effectively: one-on-one mentoring for personalized guidance and doubt-solving to ensure you never feel stuck.
 If you are preparing for IIT-JEE or any competitive exam, consult an IITIAN and get guidance now.
           </p>
           <div className={classes.iconsF}><a href=""><BsFacebook/></a>&nbsp;<a href=""><BsLinkedin/></a>&nbsp;<a href=""><BsInstagram/></a>&nbsp;<a href=""><BsTwitter/></a>&nbsp;<a href=""><BsYoutube/></a></div>
      </div>
      <div className={classes.rightFoot}>
        <form>
            <h1>For any Queries and Suggestions</h1>
            <input for="name" placeholder="Name" />
            <input for="email" placeholder="Email" />
            <input for="contact" placeholder="Contact No" />
            <textarea style={{padding:"0.5rem"}} name="message" placeholder=' Write to Us ...' />
            <button type="submit">Send</button>
        </form>
      </div>
    </div>
    <div className={classes.refund}>
      <Link to="/refund-policy">Refund Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to="/private-policy">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to="/terms-and-conditions">Terms and Conditions</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      All Rights Reserved 
      </div>
      <div className={classes.address}>MMM Hall Of Residence, IIT Kharagpur, West Mednapure, West Bengal 721302</div>
    </div>
  )
}

export default footer

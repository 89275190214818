import React from 'react'
import classes from './Uniqueness.module.css'
import Ucard from './Ucard.js'
const Uniqueness = () => {
  return (
    <div className={classes.unique}>
      <div className={classes.cov}>
            <h1 className={classes.headingU}>What sets us apart ... </h1>
            <div className={classes.main}>

                <Ucard
                    img={"https://cdn.pixabay.com/photo/2015/11/03/09/03/school-1019989_1280.jpg"}
                    title={"One-on-One Mentorship and Doubt-Solving:"}
                    text={"We understand that every student is unique. That's why we offer personalized one-on-one mentorship to cater to your specific needs. Our mentors are not just tutors; they are mentors, guides, and friends, accompanying you on your academic journey."} />
                
                <Ucard
                    img={"https://media.istockphoto.com/id/1363270874/vector/translator-app-logo-language-translation-icon-dictionary-online-translator-sign-multilingual.jpg?s=612x612&w=0&k=20&c=kkWf2AboIsAOkEmycxs9GEoOS20bUI40MZcJAEEmBgw="}
                    title={"Language Barrier? Not Here!:"}
                    text={"At IITude, we are committed to breaking down barriers. We ensure that language is never an obstacle in your quest for knowledge. You can find a mentor who speaks your mother tongue."} />

            </div>
        </div>
    </div>
  )
}

export default Uniqueness

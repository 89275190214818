import React from 'react'
import classes from './OTCard.module.css'
const OTCard = (props) => {
  return (
    <div className={classes.OTCard}>
      
      <div className={classes.box}><img src={props.img} alt="dp"></img></div>
            <div className={classes.dv1}>
                <div className={classes.pName}>{props.name} </div>
                <div className={classes.pRank}>JEE Advanced AIR - {props.rank}</div>
                <div className={classes.pInst}>{props.inst}</div>
                <p className={classes.pDesc}>{props.desc} </p>
            </div>

    </div>
  )
}

export default OTCard

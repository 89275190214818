import React from 'react'
import Api from '../API/Api'
import {useState, useEffect, useRef} from 'react'
import classes from './Profile.module.css'
import { TailSpin } from "react-loading-icons";
import { useNavigate } from "react-router-dom";
import Navbar from '../components/navbar'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const FormArea = (props) => {
    console.log(props.user);
    
    return (
      <div className={classes.containerP}>
       
            <form
              className={classes.cardP}
              onSubmit={props.handleSubmit}
              target="_blank"
              ref={props.formElement}
            >
              <h2>Profile</h2>
                
                 
                  <input
                    type="text"
                    name="firstName"
                    required="true"
                    value={props.user ? props.user?.firstName : "" }
                    disabled={!props.isEditing}
                  />
             
              
                  <input
                    type="text"
                    name="lastName"
                    value={props.user ? props.user?.lastName : "" }
                    disabled={!props.isEditing}
                  />
                  
                  <input
                    type="email"
                    required="true"
                    name="email"
                    value={props.user ? props.user?.email : "" }
                    disabled={true}
                  />
                  <div className={classes.flexDivP}>
                 
                  <input
                    type="number"
                    pattern="[0-9]{10}"
                    required="true"
                    name="phone"
                    value={props.user ? props.user?.phone : "" }
                    disabled={!props.isEditing}
                  />
                 
                  <select name="standard" value={props.user ? props.user?.standard : "" } disabled={!(props.isEditing)}>
                    <option hidden>Class</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                  </div>
            
                  <input
                    type="text"
                    required="true"
                    name="college"
                    value={props.user ? props.user?.college : "" }
                    disabled={!props.isEditing}
                  />
      
                  <input
                    value={props.user ? props.user?.city : "" }
                    type="text"
                    required="true"
                    name="city"
                    disabled={!props.isEditing}
                  />
                
            
                  <input
                    type="text"
                    required="true"
                    name="state"
                    value={props.user ? "AP" : "" }
                    disabled={!props.isEditing}
                  />
                    
                  
              {!props.isEditing ? <button
                onClick={() => props.setIsEditing(true)}
              >
                <span
                  style={{ background: "none" }}
                >
                  Edit
                </span>
              </button> : <button
                type="submit"
                onClick={() => props.setIsEditing(false)}
              >
                {props.loading ? (
                  <span style={{ marginRight: "9px", marginTop: "5px" }}>
                    <TailSpin width="20" height="12" />
                  </span>
                ) : (
                  ""
                )}
                <span
                  style={{ background: "none" }}
                >
                  Save
                </span>
              </button>}
             
            </form>
         </div>
    );
  };
  
  const Profile = (props) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [isError, setIsError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [user, setUser] = useState(null)
    useEffect(() => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      };
    
      Api.get(`/api/user/login_check`, requestOptions)
        .then((res) => {
          console.log(res?.data?.message);
          if (res?.data?.message === "login check successful") {
            setUser(res?.data?.user);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  
    let navigate = useNavigate();
  
    const formElement = useRef(null);
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      const data = Array.from(formElement.current)
        .filter((input) => input.name)
        .reduce(
          (obj, input) => Object.assign(obj, { [input.name]: input.value }),
          {}
        );
  
  
      console.log(data);
  
      setLoading(true);
  
      Api.post(`/api/users/profileEdit`, data)
        .then((response) => {
          console.log(response)
          if (response.error) {
            setError(response.error)
            throw new Error(response.error);
          }
  
          setLoading(false);
          //  setError(false)
  
          setOpen(true);
          setIsError(false);
          setIsEditing(false);
          setError("Signup Successful");
  
          navigate("/SignIn", { state: { replace: "true" } });
  
          console.log("Success");
  
          return response.json();
        })
        .catch((err) => {
          setLoading(false);
  
          // setError(err.response.data.message);
  
          setIsError(true);
          // setError(err.message)
          setOpen(true);
          // navigate('/SignUp',{message:true})
        });
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
          <div>
            {/* <Navbar /> */}
  
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={error}
            >
              <Alert
                onClose={handleClose}
                severity={isError ? "error" : "success"}
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </Snackbar>
  
            <FormArea
              handleSubmit={handleSubmit}
              formElement={formElement}
              setShow={setShow}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              loading={loading}
              user={user}
            />
          </div>
    );
  };
  
  export default Profile;

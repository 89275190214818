import React, { Component } from 'react';
import Navbar from './components/navbar.js'
import About from './pages/about.js'
import Plans from './pages/Plans.js'
import classes from './MainHome.module.css'
import FAQ from './pages/FAQ.js'
import Uniqueness from './pages/Uniqueness.js'
import WhyIITude from './pages/WhyIITude.js'
import Contact from './pages/contact.js'
import Footer from './components/footer.js'

class MainHome extends Component {
  render() {

  return (
    <div className={classes.container}>
        <Navbar />
              {/* <Link to="/SignUp">
                <div className={classes.btn} variant="contained">
                  Register for CA Programme
                </div>
              </Link> */}
      <div className={classes.home}>
      <h1 className={classes.heading}>IITude</h1>
      <h2 className={classes.head2}>"We envision a future where no dream is too big and no goal is out of reach"</h2>
      </div>
      
      <div id="about" >
          <About />
      </div>
      <div id="Uniqueness">
        <Uniqueness />
      </div>
      <div id="plans" >
        <Plans />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div id="faqs" >
        <FAQ />
      </div>
      <Footer />
    </div>
  )
}
}

export default MainHome;



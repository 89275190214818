import React from 'react'
import classes from './Refund.module.css'
const Private = () => {
  return (
    <div className={classes.containerRefund}>
      <h1>Privacy Policy - iitude.com</h1>

<p>Please read this privacy policy to learn more about the ways in which we use and protect your personal information at iitude.com. Privacy Policy describes the information, what IITude collects from you and what may happen to that information. Your privacy is very important to us. To protect your privacy, we utilize procedural and technical protections such as firewalls and encryption to secure your personal information against loss or theft, as well as unauthorized access or disclosure. To safeguard such data from unwanted access by users both inside and outside the firm, we deploy a variety of security approaches. On the Internet, however, “absolute security” does not exist.</p>

<p>You expressly consent to our use and dissemination of your personal information in line with this Privacy Policy by accepting the Privacy Policy and Terms & Conditions during registration or by visiting and using the Site.</p>

<p>If you are 13 or older but under the age of 18, you may only use this site and submit personal information to us with your parents or guardians’ permission and supervision.</p>

<h2>What information do we collect and how do we use it?</h2>

<p>Our primary goal in collecting personal data from you is to provide you with a secure, seamless, efficient, and personalized experience. This enables us to provide services and features that are most likely to fulfill your requirements, as well as to tailor our service to make your experience safer and easier. We use any information you freely submit to improve your experience on our site, including providing interactive or personalized aspects and better preparing future material based on your interests. Based on visits to certain sections, we aggregate this monitoring information to identify which portions of our site members prefer and don’t like. This allows us to continue to improve our service for you.</p>

<p>We only collect personal information about you that we believe is required to accomplish this goal.</p>

<p>You are free to explore the Site without telling us who you are or disclosing any personal information. If you want to use our Site, we may ask you to provide contact and identity information, billing information, and other personal information as indicated on the Site’s forms. We try to make it clear which fields are mandatory and which are optional. You can always refuse to disclose information by declining to use a specific service or feature.</p>

<p>Your credit card information is used to verify the accuracy of your name, address, and other personal information, as well as to bill you for the services you have used. In rare cases, we may also need your credit card details or other financial information to verify your identification. We do not keep credit card information on our servers.</p>

<h2>Based on your activities on our Site, we track certain information about you automatically.</h2>

<p>We use this data to conduct internal research on the demographics, interests, and behaviour of our users in order to better understand, protect, and serve you and our community. This information could include the URL you just came from (whether or not that URL is on our Site), the URL you travel to next (whether or not that URL is on our Site), your computer browser information, and your IP address.</p>

<p>When we have to cooperate with investigations of alleged unlawful activities and when we believe in good faith that such action is necessary to protect and defend the rights and related property of iitude.com and its visitors, iitude.com may disclose personally identifiable information about you to third parties.</p>

<h2>“Cookies”</h2>

<p>“Cookies” are little files that are stored on your computer’s hard disk to help us provide our services. We use cookies to make it easier for you to enter your password during a session. To determine which pages are being visited, we use traffic log cookies. This allows us to evaluate data about website traffic and enhance our website to better meet the demands of our customers. This information is only used for statistical analysis, after which it is deleted from the system.</p>

<p>Overall, cookies assist us in providing a better website to you by allowing us to track which pages you find beneficial and which you do not. A cookie does not allow us access to your computer or any information about you other than the information you choose to contribute. If your browser allows it, you may always refuse our cookies, albeit this may prevent you from getting the most out of the website. During a session, you may be needed to re-enter your password more frequently.</p>

<h2>Information Disclosure:</h2>

<p>We do not sell or rent any of your personal information to third parties for marketing purposes without your specific approval as a matter of policy. However, the following list explains how your personal information may be exposed in the ordinary course of business in order to deliver our services.</p>

<p>We aggregate (collect data from all user accounts) personal information and share it with advertisers and other third parties for marketing and promotional purposes in a non-personally identifiable manner. In these circumstances, however, we do not provide these businesses with any information that could be used to identify you personally. Without your explicit approval, certain personally identifiable information, such as your name, email address, password, and financial information, is not provided to these third parties in a personally identifiable manner.</p>

<p>Your Username is visible throughout the Site and thus accessible to the general audience. Your User ID will be linked to all of your activity on the Site. Your published content, such as lessons, exams, forum postings, questions, comments, course and tutor offerings, authority, and so on, is visible to others. As a result, if you link your name to your User ID, the persons to whom you’ve exposed your identity will be able to track your iitude.com activities.</p>

<h2>Embedded Links To Other Websites:</h2>

<p>Our website may include links to other useful websites. However, once you leave our site via these links, you should be aware that we have no control over the other website. As a result, we cannot guarantee the security or privacy of any information you supply while visiting such sites, whether they are teachers or students on our Site or elsewhere on the Internet. Different regulations may apply to their use or disclosure of personal information you provide to them. This privacy statement does not apply to such sites. You should proceed with caution and carefully read the privacy policy for the website in question.</p>

<h2>Reviewing And Changing Your Personally Identifiable Information:</h2>

<p>After registering, you can review and edit some of the information you provided during the registration process. If your information changes, you must promptly update it. We will delete your account and remove your contact information from our active databases if you request it. Please send an email to iitude137@gmail.com to make this request. Based on your account behaviour and in compliance with our deactivation policy and applicable law, such information will be deactivated as soon as practically possible.</p>

<p>To prevent fraud, recover any fees owing, handle disputes, troubleshoot problems, assist with any investigations, enforce our Terms and Conditions, and comply with legal requirements, we shall maintain some personal information you have requested to be removed from our files. As a result, you should not expect us to remove all of your personal information from our databases in response to your requests. However, such personal information will be hidden from the public and member views, and only a few iitude.com employees will have access to it.</p>

<p>Note that iitude.com reserves the right to change this policy at any time. Our services are subject to change from time to time.</p>

<h2>Contact Us:</h2>

<p>If you would like to contact us for any reason regarding our privacy practices, please write to us at the following address:</p>

<p>Email us at: iitude137@gmail.com</p>

<p>Note: iitude.com may amend this policy from time to time. Our services are subject to change at any given point of time.</p>
    </div>
  )
}

export default Private

import React from 'react'
import classes from './Refund.module.css'

const TnC = () => {
  return (
    <div className={classes.containerRefund}>
      <h1>Terms And Conditions - IITude.com</h1>
  <p>We recommend to read the following very carefully to obtain maximum benefit of IITude.com</p>

  <h2>1. Acceptance of Terms</h2>
  <p>Welcome to IITude.com owned and operated by IITude. By clicking “I Agree” on the book now page/payment page/submission page or using the website in any way, you are agreeing to comply with and be bound by this submission page or agreement, the Privacy Policy and all rules, policies and disclaimers posted on this website or about which you are notified. Your agreement shown by accessing and using this website is legally binding. If you do not agree to all these terms and conditions, do not access this website.</p>
  <p>People under the age of 18 may use this website and submit personal information to us only in conjunction with and under the supervision of their parents or guardians. The user of this website by a person under the age of 18 constitutes the agreement of the parent or guardian to these Terms and Conditions.</p>
  <p>If you are an individual entering into this Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to this Agreement. If you do not agree with each provision of this Agreement, or you are not authorized to enter into this Agreement, or you do not have the legal capacity to enter into this Agreement, you may not enter into the Agreement or use the Service.</p>
  <p>By registering on IITude website or by obtaining consultancy services at IITude.com, you authorize the company to use your name and photographs in advertisements and promotions to showcase you as a user of the services.</p>

  <h2>2. Definitions</h2>
  <p>In the Terms, “User,” “you” and “your” refer to the individual or entity that interacts with the services provided by the site IITude.com as a Customer and/or Mentor/Expert and/or tutor. “IITude.com”, “IITude”, “we,” “us” and “our” refer to IITude. “Customer” refers to the person who applies for services provided by the website. “Mentor/Expert/Tutor” refers to the person who provides guidance on the website. They both are marginally different but both use website resources. “Moderator and / or Manager” refers to the person moderating user post’s and all transactions taking place.</p>

  <h2>3. Right To Modify Terms</h2>
  <p>When you visit IITude.com or send us e-mails, you are communicating with us thorough electronic media. You consent to receive communications from us electronically. We will communicate with you by e-mails or chats or pop-ups, by posting notices anywhere on website IITude.com. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You agree to provide us with your current and active email address.</p>
  <p>If you do not accept the changes, your sole and exclusive remedy is to discontinue using the website. IITude reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) or prices with or without notice. You agree that IITianguide shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</p>
  <p>We may change these terms and conditions, and you are responsible for regularly reviewing and complying with them. Your continued use of this website constitutes your agreement to all those terms and conditions.</p>

  <h2>4. Description Of Service</h2>
  <p>The Service consists of the provision by IITude to you of access to the IITude website, which provides tools to you that will enable you to view online content (in the form of text, images, video, flash), take online tests, discussion forms, free demos, exams solutions, past year papers connect with other members and online tutoring services for sale via, and purchase goods and services offered by parties other than IITude (“Third Parties”) in, an online marketplace. Unless explicitly stated otherwise, any new features that augment or enhance the current Service shall be subject to the terms and conditions of this Agreement. The Service may not be used for any purpose not expressly permitted by this Agreement. You may not reproduce, copy, duplicate, sell or resell any part of the Service.</p>
  <p>The IITude website is a venue for informational and educational purposes to allow Customers/Students to study and ask questions and Experts to answer them. The study material on site is not a direct property of IITude.com but an indirect online resource resulting from common user participation building up the website. The content study material under any page of site may or may not reflect a similarity from any other recourse as it is a user portal.</p>
  <p>Notwithstanding the foregoing, IITude reserves the right, but is not obligated, to refuse to post or to remove any content. Any particular post (though closely moderated) falling under human rights violation, treated as insecure content, porn content, abusive, illegal, unauthorized, emotional, sensitive, communal, hatred, defaming someone will be treated as an instance of happening and will be removed on immediate request without any information to the troll/user/expert.</p>

  <h2>5. Content And License</h2>
  <p>All content on the website and available through the Service, including but not limited to designs, text, graphics, pictures, video, information, applications, software, sound and other files, and their selection and arrangement (the “website Content”), is the property of IITude, its users or its licensors, with all rights reserved. No website Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without IITude’s prior written permission, except that (i) the foregoing does not apply to your own User Content (as defined below) that you legally post on the website, and (ii) any website Content provided by Third Parties for sale or license through the website (“Third Party Content”) is subject to the terms and conditions established by those Third Parties from time to time, as described on the website (“Third Party License Terms”).</p>
  <p>Provided that you are eligible for use of the website, you are granted, solely for your personal, non-commercial use, a limited license to access and use, and to download or print a copy of, any portion of the website Content, other than Third Party Content, to which you have properly gained access, provided that you keep all copyright or other proprietary notices intact. Such license is subject to these Terms of Use and does not include use of any data mining, robots or similar data gathering or extraction methods.</p>
  <p>Your rights to use Third Party Content are as described in the applicable Third Party License Terms. Any use of the website or the website content other than as specifically authorized herein, without the prior written permission of IITude the applicable Third Party, as the case may be, is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws including without limitation copyright and trademark laws. Unless explicitly stated herein, nothing in these Terms of Use shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable at any time without notice and with or without cause.</p>
  <h2>6. Trademarks</h2>
  <p>IITude graphics, logos, designs, page headers, button icons, scripts and service names are registered trademarks, trademarks or trade dress of IITude. Trademarks and trade dress used by Third Parties in association with the sale or license of their goods or services on the website are the property of the respective owner. Such trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the respective owner.</p>

  <h2>7. You Agree That You Will Not:</h2>
  <p>Harm others in any way in your use of the Service.Impersonate any person or entity, including, but not limited to, an IITude official, guide or host, or falsely state or otherwise misrepresent your affiliation with a person or entity.Interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service.</p>

  <h2>8. License By You Of Content</h2>
  <p>By posting Third Party Content to the website, you automatically grant, and you represent and warrant that you have the right to grant, to IITude perpetual, non-exclusive, transferable, fully-paid, worldwide license to use, copy, publicly perform, publicly display and distribute such Third Party Content on the website to the extent necessary to promote such Third Party Content and support your licensing and distribution of it through the website.</p>
 

  <h2>9. Complaints</h2>
  <p>IITude respects the intellectual property and other rights of others and these Terms of Use prohibit users from uploading, posting or otherwise transmitting on the website or through the Service any materials that violate another party’s intellectual property and other rights. IITude is not responsible for the conduct of its users, and has no responsibility or liability for User Content. When we receive properly documented complaints about User Content such complaints are forwarded to the applicable user for the user’s action. IITude is not responsible for any user’s action or inaction as a result of any such notification. If you believe that any material on the website infringes upon your intellectual property or other rights you may notify us at iitude137@gmail.com</p>

  <h2>10. Services</h2>
  <p>IITude may from time to time offer goods and services through the website and may offer you or other Third Parties the ability to offer goods and services for sale to Third Parties through the website. All transactions conducted in connection with this aspect of the Service are subject to and governed by such guidelines as IITude posts from time to time (the “Guidelines”) as well as this Agreement. The Guidelines are subject to change without prior notice at any time, in IITude sole discretion, so you should review the Guidelines each time you use this aspect of the Service.</p>
    </div>
  )
}

export default TnC

import {useEffect, React, useState} from 'react'
import classes from './cardPlan.module.css'
import { FiSend , } from 'react-icons/fi'
import {FaHandPointRight} from 'react-icons/fa'
import Api from "./API/Api"
import { Link } from 'react-router-dom';
import Modal from './pages/Disclaimer';

function Card({butVis, plan, amount, title, noOfSessions, desc, checkoutHandler}) {

  const [userC, setUserC] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  useEffect(() => {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    };


    Api.get(`/api/user/login_check`, requestOptions).then((res) => {

      // console.log(res.data);
      // console.log(res?.data?.message);
      if(res?.data?.message == "login check successful"){
        setUserC(res?.data?.user);
        // console.log(userC);
      }
    }).catch((err) => {
      console.log(err);
    })


  }, [])

  const colors1 = ['#5D69BE', '#d9d9d933', '#6CD0FF', '#C8A2C8', '#32CD32', '#008080', '#9370DB', '#3CB371', '#FF6347'];
  const colors2 = ['#C89FEB', '#1FC5A8', '#1C2E4C', '#d9d9d933', '#00CED1', '#FF4500', '#00FA9A', '#8B4513', '#87CEFA'];
  


  // // Randomly select two colors, one from colors1 and one from colors2
  // const randomIndex1 = Math.floor(Math.random() * colors1.length);
  // const randomIndex2 = Math.floor(Math.random() * colors2.length);
  // // const randomIndex2 = Math.floor(Math.random() * colors2.length);
  // const selectedColor1 = colors1[randomIndex1];
  // const selectedColor2 = colors2[randomIndex2];
  // console.log(selectedColor1);
  // console.log(selectedColor2);

  return (
    <div className={classes.card} style={{ background: "#d9d9d933" }}>
      <div className={classes.name} style={{background: '#191970'}}>{title}</div>
      {noOfSessions > 0 ? <div className={classes.amount}>Rs {amount} /-{noOfSessions==0 && <div className={classes.glowingtext}>Limited Offer</div>}<div>per {noOfSessions} session</div><div>{noOfSessions} Audio/Video Calls</div></div>
      :
      <div className={classes.amount}>Rs {amount} /-</div>
      }
      {desc?.map((point) => (
        <div className={classes.desc}><FaHandPointRight/>&nbsp;&nbsp;{point}</div>
      ))}
   
   {butVis ? (
  userC ? (
    <button
    onClick={()=>checkoutHandler(amount)}
    //  onClick={handleOpenModal}
      style={{ backgroundColor: '#191970' }}
    >
      Buy
      {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal} /> */}
    </button>
    
  ) : (
    <button style={{ backgroundColor: '#191970' }}>
      <Link to="/Signup"><span style={{ color: 'white' }}>Register and Buy</span></Link>
    </button>
  )
) : <button style={{ backgroundColor: '#191970' }}>Registered</button>}

    
    </div>
  )
}






export default Card
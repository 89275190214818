import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios';
import classes from './Plans.module.css'
import Card from '../card'
import Api from '../API/Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const Dashboard = () => {

  const updateUserPlans = async (user, plan) => {
    try {
      const data = {
        user: user,
        plan: plan,
      };
  
      const response = await Api.post("/api/users/updateUsersPlans", data);
      console.log(response)
  
      if (response.status === 200) {
        console.log('User plans updated successfully');
        // You can return the response or any other data you want
        return response.data; // Return the response data, if applicable
      } else {
        console.error('Failed to update user plans');
        // Handle the error as needed
        throw new Error('Failed to update user plans'); // Optionally, throw an error
      }
    } catch (error) {
      console.error('Error while updating user plans:', error);
      // Handle the error as needed
      throw error; // Optionally, rethrow the error
    }
  };
  
    const checkoutHandler = async (user, plan) => {
        // console.log("hehe", plan.cost)
        const amount = plan.cost
         
        const {data:{key}} = await Api.get("/api/getKey")
    
        const { data:{order} } = await Api.post("/api/checkout", {
            amount
        })
          console.log(order);
        const options = {
            key: key, // Enter the Key ID generated from the Dashboard
            amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Narendra Gandhi",
            description: "Test Transaction",
            image: "https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510_640.jpg",
            order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: async function (response){
              if(response.razorpay_payment_id){
                const res = await updateUserPlans(user, plan);
                console.log('Payment successful', res);
              }
              const res = await Api.post("/api/paymentverification",response)
              if(res.data.success){
                alert("Payment Successful");
              }
              else alert("Payment failed please try again...")
              window.location.reload()
              
            },
            // callback_url: "https://iitude.onrender.com/api/paymentverification",
            prefill: {
                name: "Narendra Gandhi",
                email: "gaurav.kumar@example.com",
                contact: "9000090000"
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                "color": "#3399cc"
            }
        };
        const razor = new window.Razorpay(options);
        razor.on('payment.success', async (response) => {
          // The payment is confirmed; now you can update the user's plans
          const res = await updateUserPlans(user, plan);
          console.log('Payment successful', res);
        });
        razor.open();
      }  
 const [plans, setPlans] = useState(null);
 const [users, setUsers] = useState([]);
 const getPlans = async () => {
    const list = await Api.get("/api/plans")
    // console.log("list", list.data)
    setPlans(list.data.data);
    // console.log("hehe", plans)
}
const getUsers = async () => {
   try{
    const data = await Api.get('/api/users');
    console.log(data, "hhhh")
   setUsers(data.data);
   console.log("users", users);
   }
   catch(err){
    console.log(err);
   }
}
useEffect(() => {
 getPlans();
 getUsers();
}, []);
const [user, setUser] = useState(null);
const [isAdmin, setIsAdmin] = useState(false);
useEffect((props) => {

    const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    };


    Api.get(`/api/user/login_check`, requestOptions).then((res) => {

    // console.log(res.data);
    console.log(res?.data?.message);
    if(res?.data?.message == "login check successful"){
        setUser(res?.data?.user);
        if(user?.role === "admin"){setIsAdmin(true)};
        console.log("admin", isAdmin);
        console.log("admin", user?.role);
    }
    }).catch((err) => {
    console.log(err);
    })


}, [user])
const [points, setPoints] = useState([]);
const [pointInput, setPointInput] = useState('');
const [planName, setPlanName] = useState('');
const [planCost, setPlanCost] = useState('');
const [planSessions, setPlanSessions] = useState('');
const [isCreate, setIsCreate] = useState(true);
const [isDivVisible, setDivVisibility] = useState(false);
const [isSeeAllUsers, setIsSeeAllUsers] = useState(true);
const [isSDivVisible, setIsSDivVisible] = useState(false);
const [isSeeAllRUsers, setIsSeeAllRUsers] = useState(true);
const [isRDivVisible, setIsRDivVisible] = useState(false);
const [planDivV, setPlanDivV] = useState(true);

  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
    setIsCreate(!isCreate);
  };
  const toggleSVisibility = () => {
    setIsSDivVisible(!isSDivVisible);
    setIsSeeAllUsers(!isSeeAllUsers);
  };

  const toggleRVisibility = () => {
    setIsRDivVisible(!isRDivVisible);
    setIsSeeAllRUsers(!isSeeAllRUsers);
  };
  const togglePlanDivV = () => {
    setPlanDivV(!planDivV);
  }

const addPoint = (e) => {
    if (pointInput.trim() !== '') {
        e.preventDefault();
        setPoints([...points, pointInput]);
        setPointInput('');
    }
};
const removePoint = (index) => {
    const updatedPoints = [...points];
    updatedPoints.splice(index, 1); // Remove the point at the specified index
    setPoints(updatedPoints);
  };

const createPlan = async() => {
    const planData = {
        title: planName,
        cost: planCost,
        noOfSessions: planSessions,
        desc: points,
      };
      console.log(planData)

    Api.post("/api/plans/postPlan", planData)
    .then((response) => {
        console.log(response)
        window.location.reload();
        toggleDivVisibility();
        return response.json();
      })
      .then((data) => {
        // Handle the response from the backend if needed
        console.log("hehe", data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch.
        console.error('Error:', error);
      });
}

return (
    <div className={classes.dashboardC}>
    <div className={isAdmin ? classes.createPlanDiv : classes.createDiv}>
    <button onClick={toggleDivVisibility}>{isCreate ? "Create Plan" : "Back" }</button>
      {isDivVisible && <div>
        <form onSubmit={(e) => e.preventDefault()}>
            <input placeholder='Name of the Plan'required="true" onChange={(e)=>setPlanName(e.target.value)}/>
            <input placeholder='Cost of the Plan' required="true" onChange={(e) => setPlanCost(e.target.value)}/>
            <input placeholder='No of Sessions in the Plan' required="true" onChange={(e) => setPlanSessions(e.target.value)}/>
            <input type="text" placeholder="Enter points" value={pointInput}
            onChange={(e) => setPointInput(e.target.value)}
      />
      <button onClick={addPoint}><FontAwesomeIcon icon={faPlus} /></button>
      <button onClick={createPlan}>Create</button>
        </form>
        {points.length && <div className={classes.points}>
                <h2>Points List</h2>
                <ul>
                {points.map((point, index) => (
                    <li key={index}>{point}
                    <button onClick={() => removePoint(index)}><FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} /></button>
                    </li>
                ))}
                </ul>
         </div>}
        
        </div>}
        <button onClick={toggleSVisibility}>{isSeeAllUsers ? "See All Users" : "Back"}</button>
        {isSDivVisible && <div>
          <table className={classes.Stable}>
      <thead>
        <tr>
          <th style={{alignItems:"center"}}>Total users SignedUp:{users?.length}</th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Age</th>
          <th>No Of Plans Registered</th>
          {/* <th>Plan Ids</th> */}
        </tr>
      </thead>
      <tbody>
      {users?.map((item) =>  (
    <tr key={item._id}>
      <td>{item.firstName}&nbsp;{item.lastName}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.noOfPlans}</td>
      {/* <td>{item.planIds}</td> */}
    </tr>
  )
)}
      </tbody>
  </table>
          </div>}
          <button onClick={toggleRVisibility}>{isSeeAllRUsers ? "See Registered Users" : "Back"}</button>
        {isRDivVisible && <div>
          <table className={classes.Stable}>
      <thead>
        <tr>
          <th style={{alignItems:"center"}}>Total users SignedUp:{users?.length}</th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Age</th>
          <th>No Of Plans Registered</th>
          {/* <th>Plan Ids</th> */}
        </tr>
      </thead>
      <tbody>
      {users?.map((item) => (
  item.noOfPlans > 0 && (
    <tr key={item._id}>
      <td>{item.firstName}&nbsp;{item.lastName}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.noOfPlans}</td>
      {/* <td>{item.planIds}</td> */}
    </tr>
  )
))}
      </tbody>
  </table>
          </div>}
    </div>
    <div className={classes.plansCurrent}><h2 >{planDivV ? "Available Plans" : "Your Plans"}</h2><button onClick={togglePlanDivV}>{planDivV ? "See your plans" : "See current plans"}</button></div>
    {planDivV && <div className={classes.planDiv}>  
    {plans?.filter((plan) => !user?.planIds.includes(plan._id)).map((plan) => (
    <Card
      // key={plan._id} // Ensure you provide a unique key
      butVis={true}
      plan={plan}
      title={plan.title}
      amount={plan.cost === 399 ? <><span className={classes.strikethrough}>399</span>&nbsp;&nbsp;249</> : plan.cost}
      noOfSessions={plan.noOfSessions}
      desc={plan.desc}
      checkoutHandler={checkoutHandler}
    />
  ))}
    </div>}
    {!planDivV && <div className={classes.userplanDiv}>  
    {plans?.filter((plan) => user?.planIds.includes(plan._id)).map((plan) => (
    <Card
      // key={plan._id} // Ensure you provide a unique key
      butVis={false}
      plan={plan}
      title={plan.title}
      amount={plan.cost === 399 ? <><span className={classes.strikethrough}>399</span>&nbsp;&nbsp;249</> : plan.cost}
      noOfSessions={plan.noOfSessions}
      desc={plan.desc}
      checkoutHandler={checkoutHandler}
    />
  ))}
    </div>}
    </div>
  )
}

export default Dashboard

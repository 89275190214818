import React from 'react'
import classes from './Ucard.module.css'
const Ucard = (props) => {
  return (
    <div className={classes.UmainA}>
      <div className={classes.Uimg}>
      <img src={props.img} alt=""/>
      </div>
      <div className={classes.UmainB}>
      <div className={classes.tit}>{props.title}</div>
      <p className={classes.para}>{props.text}</p>
      </div>
    </div>
  )
}

export default Ucard

import React from 'react'
import { MdEmail, MdFacebook } from 'react-icons/md';
import { AiFillLinkedin } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import classes from './Ccard.module.css'

const Ccard = (props) => {

    const email = "mailto:" + props.email;
    
    const whatsappNumber = '8498088512'; // Replace with your WhatsApp phone number
    
    const handleWhatsappClick = () => {
        const whatsappLink = `https://wa.me/${whatsappNumber}`;
        window.location.href = whatsappLink;
    };

    return (
        <div className={classes.Ccard}>
            <div className={classes.circle}>
                <img src={props.img} alt={props.name}></img>
            </div>
            <div className={classes.secondDiv}>
                <div className={classes.name}>{props.name}</div>
                {/* <div className={classes.pos}>{props.pos}</div> */}
                <div className={classes.icons}><a href={email}><MdEmail /></a>&nbsp;&nbsp;&nbsp;<a href={props.fb} target="_blank"><MdFacebook /></a>&nbsp;&nbsp;&nbsp;<a href={props.li} target="_blank"><AiFillLinkedin /></a></div>
                <div className={classes.phNo}><FaWhatsapp onClick={handleWhatsappClick} style={{ cursor: 'pointer' }} />&nbsp;&nbsp;8498088512</div>
            </div>
        </div>
    )
}

export default Ccard
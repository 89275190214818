import React from 'react'
import classes from './ourTeam.module.css'
import OTCard from './OTCard'
import Carousel from 'react-material-ui-carousel'
import ArrowBackIosTwoToneIcon from '@mui/icons-material/ArrowBackIosTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';

function CarouselComponent() {
  // const VIDEO_PATH = '.src/videos/Ca Testes.mp4'
  
    return (
      <div className={classes.teamC}>
        <h1 className={classes.teamH}>Our Team</h1>
        <Carousel className={classes.teamCara}
          NextIcon={<ArrowForwardIosTwoToneIcon />}
          PrevIcon={<ArrowBackIosTwoToneIcon />}
          autoPlay={false}
          indicators={true}
          animation={"slide"}
          navButtonsAlwaysVisible={true}
          navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS classes BOTH BUTTONS
            style: {
              backgroundColor: 'transparent',
              color: 'black',
              transform: 'scale(2)',
              borderRadius: 0,
              margin:0
              
            }
          }}
          
          // navButtonsWrapperProps={{
          //   style:{
          //     height:'10rem',
          //     animation:'none'
          //   }
          // }}
  
          
          indicatorIconButtonProps={{
            
            style: {
              paddingTop:"10px",
              marginTop: '2rem'
              
            }
          }}
        >
          <OTCard name="Nihith Maddi" img="https://i.postimg.cc/26GQ7HCf/nm.jpg" inst="Computer Science and Engineering, IIT Kharagpur" rank="268" desc="Greetings! I'm Nihith, an undergraduate of IIT Kharagpur.
 My academic journey was shaped by the challenges of competitive exams, and I am here to guide you through every step.
 Let's conquer those exams together!"></OTCard>
    
          <OTCard name="Harshith Chowdhary Meduri" img="https://i.postimg.cc/kGw9z8xq/hc.jpg" inst="Computer Science and Engineering, IIT Kharagpur" rank="585" desc="Hey there! I'm Harshith, your guide from IIT Kharagpur.
 I believe in the power of personalized mentoring to bring out the best in each student.
 Let's embark on this academic journey together!"></OTCard>
  
          <OTCard name="Anjaneya Choda" img="https://i.postimg.cc/WbPXfC3N/an.jpg" inst="Computer Science and Engineering, IIT Kharagpur" rank="1103" desc="Hello future leaders! I'm Anjaneya, an undergraduate from IIT Kharagpur.
 Join me in this journey where we'll explore the intricacies of competitive exams and navigate through challenges with a winning mindset."></OTCard>
  
          <OTCard name="Chaitanya Srinivas Dommeti" img="https://i.postimg.cc/QdwN7BDm/cs.jpg" inst="Electronics & Electricl Communication Engineering, IIT Kharagpur" rank="2414" desc="Hola amigos! I am Chaitanya, your mentor from IIT Kharagpur.
 The journey through competitive exams is challenging, but with the right guidance and strategy, success is inevitable.
 Let's achieve greatness together!"></OTCard>
  
          <OTCard name="Abhilaash Tirlangi" img="https://i.postimg.cc/MTrfg3wb/ab.jpg" inst="Electronics & Electricl Communication Engineering, IIT Kharagpur" rank="2667" desc="Hi, I'm Abhilaash, a Doubt Resolver at IITude. My mission is to ensure no question lingers unanswered.
 I'm here to provide clear explanations and support, making your learning experience seamless."></OTCard>
  
          <OTCard name="Gowtham Maddula" img="https://i.postimg.cc/LXx86w9D/gk.jpg" inst="Mathematics and Computing, IIT Kharagpur" rank="2751" desc="Hello! I am Gowtham, your Doubt Resolver at IITude. I understand the importance of clarity in learning.
 Let's work together to ensure your doubts are resolved promptly and comprehensively."></OTCard>
         
          <OTCard name="Manoj Kumar Panneru" img="https://i.postimg.cc/4xJV0SHq/mk.jpg" inst="Electrical Engineering, IIT Kharagpur" rank="3899" desc="Hola! I'm Manoj, your Doubt Resolver at IITude. My goal is to make the learning journey smoother by tackling every doubt you encounter.
 Let's make your academic experience truly enriching!"></OTCard>
          <OTCard name="Ananta Kedar Sarma" img="https://i.postimg.cc/ryB9fjJ2/ks.jpg" inst="Physics Department, IIT Kharagpur" rank="4817" desc="Hey, I'm Kedar, a Doubt Resolver dedicated to enhancing your learning experience.
 Submit your doubts, and I'll provide detailed explanations, ensuring you grasp each concept thoroughly."></OTCard>

         
        </Carousel>
      </div>
    )
  }
   export default CarouselComponent;

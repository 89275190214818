import React from 'react'
import Ccard from './Ccard'
import classes from './contact.module.css'
const contact = () => {
  return (
    <div className={classes.contact}>
      <Ccard img="https://i.postimg.cc/K8g9jNF5/NG.jpg" name="Narendra Gandhi" email="narendragandhi2004@gmail.com" li="https://www.linkedin.com/in/narendra-gandhi-63b538242/" fb="https://www.facebook.com/profile.php?id=100078044880797&sk=photos"></Ccard>
    </div>
  )
}

export default contact

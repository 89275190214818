import React, { useEffect, useState } from 'react'
import Card from '../card'
import axios from 'axios'
import classes from './Plans.module.css'
import Api from "../API/Api"
import { Link } from 'react-router-dom';
// import Razorpay from 'razorpay'
const HomePlans = () => {
  const [userC, setUserC] = useState(null)
  const [visiblePlans, setVisiblePlans] = useState(3);
  useEffect(() => {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    };


    Api.get(`/api/user/login_check`, requestOptions).then((res) => {

      // console.log(res.data);
      console.log(res?.data?.message);
      if(res?.data?.message == "login check successful"){
        setUserC(res?.data?.user);
        console.log(userC);
      }
    }).catch((err) => {
      console.log(err);
    })


  }, [])
  const checkoutHandler = async (amount) => {
    console.log("hehe", amount)
     
    const {data:{key}} = await Api.get("/api/getKey")

    const { data:{order} } = await Api.post("/api/checkout", {
        amount
    })
    console.log("key",key);
      console.log("order", order);
    const options = {
        key: key, // Enter the Key ID generated from the Dashboard
        amount: Number(order.amount)/100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "DANGETI NARENDRA GANDHI",
        description: "Payment for purchasing Plan",
        image: "https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510_640.jpg",
        order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: "https://iitude.onrender.com/api/paymentverification",
        prefill: {
            name: "Narendra Gandhi",
            email: "iitude137@gmail.com",
            contact: "8074449641"
        },
        notes: {
            address: "MMM Hall Of Residence, IIT Kharagpur, West Midnapore, West Bengal - 721302"
        },
        theme: {
            "color": "#3399cc"
        }
    };
    const razor = new window.Razorpay(options);
    razor.open();
  }  
  const [plans, setPlans] = useState([])
  const getPlans = async () => {
       const list = await Api.get("/api/plans")
       console.log("list", list.data)
       setPlans(list.data.data);
       console.log("hehe", plans)
  }
  useEffect(() => {
    getPlans();
  }, []);
  
  return (
    <div className={classes.containerPlan}>
      <h1 className={classes.hP}>Plans</h1>
    <div className={classes.planDiv}>  
      {plans &&
    [
      ...plans.slice(0, 3),
    ].map((plan) => (
      <Card
  key={plan._id}
  butVis={!userC?.planIds.includes(plan._id)}
  plan={plan}
  title={plan.title}
  // Check if the cost is 399 and display a message instead
  amount={plan.cost}
  // {plan.cost === 399 ? <><span className={classes.strikethrough}>399</span>&nbsp;&nbsp;249</> : plan.cost}
  noOfSessions={plan.noOfSessions}
  desc={plan.desc}
  checkoutHandler={checkoutHandler}
/>
    ))}
    </div>
    <div className={classes.seeAll}>{userC ? <button><Link to="/Dashboard">See all plans</Link></button> : <button><Link to="/Signup">Log in and See all plans</Link></button>}</div>
</div>
  )
}

export default HomePlans

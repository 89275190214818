import axios from "axios";

const devUrl = "http://localhost:4000/"

const productionUrl = "https://iitude.onrender.com"


const Api = axios.create({
 baseURL:productionUrl,
});

export default Api;

import React, { useState, useEffect } from "react";
import classes from "./navbar.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import Api from '../API/Api'
import { Link } from 'react-router-dom';


const Navbar = (props) => {
  const [user, setUser] = useState(null)
  console.log(props.show);
  useEffect((props) => {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    };


    Api.get(`/api/user/login_check`, requestOptions).then((res) => {

      // console.log(res.data);
      console.log(res?.data?.message);
      if(res?.data?.message == "login check successful"){
        setUser(res?.data?.user);
        console.log(user);
      }
    }).catch((err) => {
      console.log(err);
    })


  }, [])
  const calllogout=()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('user')
    
    window.location.href = '/'; 
    // alert('successfully Logout!!')
    // <Link to="/SignIn"></Link>

  }
  const find=()=>{
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    };
    
    Api.get(`/api/user/login_check`, requestOptions).then((res) => {

      // console.log(res.data);
      // console.log(res?.data?.user);
      if(res?.data?.user){
        setUser(res?.data?.user);
        console.log(user);
        return true;
      }
    })
    return false;
  }
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <>
      < div className={classes.main_nav}>

        <div className={classes.logo} onClick={handleReload}>

          <img src="https://i.postimg.cc/PJkHfktP/logoo-2-removebg.png" alt="" />
          <span className={classes.desktopOnly}>IITude</span>
        </div>


        <div
          className={
            showMediaIcons ? `${classes.menu_link} ${classes.mobile_menu_link}` : `${classes.menu_link}`
          } >
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#Uniqueness">Uniqueness</a>
            </li>
            <li>
              <a href="#plans">Plans</a>
            </li>
            <li>
              <a href="#contact">Contact Us</a>
            </li>
            <li>
              <a href="#faqs">FAQs</a>
            </li>
            <li>
              <button className={classes.sign} >
                      <Link to="/OurTeam">OurTeam</Link>
              </button>
            </li>
            <li>
              <div className="button">
                {!user ? (<><button
                  className={classes.sign}>
                  <Link to="/Signup">Sign Up</Link>
                </button>
                  <button className={classes.sign} >
                    <Link to="/SignIn">Sign In</Link>
                  </button></>) : (<><button
                    className={classes.sign}>
                    <Link to="/Profile">Profile</Link>
                  </button>
                  <button
                    className={classes.logoutdevice} onClick={()=>calllogout()}>
                     {/* {user.first_name} */}
                     logout
                  </button>
                  {user ? <button className={classes.sign} >
                    <Link to="/Dashboard">Dashboard</Link>
                  </button> :""}
                  </>)

                }

              </div>
            </li>
          </ul>
        </div>

        <div className={classes.hamburger_menu}>
          <a onClick={() => setShowMediaIcons(!showMediaIcons)
          }>
            <GiHamburgerMenu style={{ color: "white", marginBottom: "0.5rem", userSelect: "none" }} />
          </a>
        </div>
      </div>
    </>

  );
};
export default Navbar;

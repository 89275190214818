import React from 'react'
import classes from "./Refund.module.css"
const Refund = () => {
  return (
    <div className={classes.containerRefund}>
      <h1>Refund And Cancellation Policy - iitude.com</h1>

<p>Please read this refund and cancellation policy to learn more about the ways in which refund is initiated and processed at iitude.com. Our focus is on complete customer satisfaction. Please read the fine points and descriptions of each service/product before buying it, it provides all the details about the services or the product you purchase.</p>

<h2>Our Policy for the cancellation and refund will be as follows:</h2>

<h3>Cancellation Policy:</h3>

<p>There is no cancellation of the plans once you opt for the services. For mentoring services however, we will try to change your assigned mentor to give you a better mentoring experience after the proper and genuine investigation into the matter.</p>

<h3>Refund Policy:</h3>

<p>Situations in which FULL REFUND may be considered:</p>

<ol>
<li>For mentoring services, if iitude is not able to assign you the IITian mentor within 4 Working days of Payment Confirmation and Aspirant Form Submission, we will transfer the money into the same account. You need to email us your account no. and other bank details at iitude137@gmail.com.</li>

</ol>

<p>If the user requests a refund, the course administrator and the accounts team both receive a refund request for the whole amount. All qualified refund requests should be processed within 45 working days of submission.  
  When computing the refund, 10% of the total fees would be subtracted for handling charges(s).</p>

<p>Situations in which NO REFUND will be given:</p>

<ul>
<li>If You have paid for the subscription and we have assigned the mentor to you within 4 days and you don’t wish to take the sessions in that case, no refund will be given to you. Please Note: If you wish to cancel it for any reason after taking the subscription, No refund will be initiated for the same. Please be sure that you really need to take the subscription before making any payments.</li>

<li>If you are unhappy with the services, no partial or refund will be given in this scenario. Only possibility which exists in this scenario is that we will consider to change your assigned mentor/tutor after carefully considering all the valid points and reasons. In this scenario, your subscription will resume from that point only and no previously used minutes will be given to you.</li>

<li>If you are unhappy with the services, no partial or any refund will be given in this scenario. The only possibility which exists in this scenario is that we will consider changing your assigned mentor/tutor after carefully considering all the valid points and reasons. In this scenario, your subscription will resume from that point only and no previously used minutes will be given to you.</li>

<li>Suppose, you have completed your package minutes and not happy with the services, No refund will be given in this scenario. You need to re-opt for the packages and we will consider changing your previous mentor/tutor. But you will have to re-pay for the new package.</li>

<li>You need to take your sessions under the validity period of the subscription, failing to do so will cancel all your subscriptions and no refund will be given in that scenario. You need to re-opt for the packages and restart your subscription. In this scenario, we can give you the previously assigned mentor/tutor.</li>

<li>The mentor/tutor holds the choice to change your appointment time and slot if they feel like it. No complaints will be taken into consideration for that.</li>

<li>If you won’t be able to take a session on the assigned slot, you must tell the mentor/tutor at least 12 hours before the booked slot, failing to do so will cancel your 40 mins from the package.</li>

<li>Suppose you wish to cancel your package subscription in between because of any reason, no partial or any refund will be given to you.</li>

<li>Usually, sessions happen once in a week and the mentor/tutor has the freedom to choose the time slot for you. You can request the mentor/tutor to have the session as soon as possible but he/she will have the final say in choosing the day and time for you.</li>
</ul>

<p>Note: iitude.com may amend this policy from time to time. Our services are subject to change at any given point of time.</p>

</div>
  )
}

export default Refund

import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import classes from './FAQs.module.css'
const data = {
    title: "FAQs",
    rows: [
        {
            title: "What is IITude?",
            content: `IITude is a distinguished educational consultancy founded by the students of the prestigious IIT Kharagpur.
            We provide one-on-one mentoring and comprehensive doubt-solving plans to help students excel in competitive exams such as IIT-JEE, NTSE, KVPY, BITSAT, and Olympiads.
             When you choose IITude, you are not just enrolling in an educational
             consultancy but joining a community of like-minded individuals who share your dreams and aspirations.
             Our goal is to prepare you for exams and empower you with the skills, knowledge, and confidence to excel in life.`,
        },
        {
            title: "What types of plans do we provide?",
            content:
                `We provide:
                1. One-on-one mentoring
                2. Doubt-Solving`,
        },
        {
            title: "Who will conduct the mentoring sessions?",
            content: `Mentoring sessions are conducted by experienced mentors who have excelled in competitive exams and are studying at prestigious institutions like IIT Kharagpur, IIT Bombay, and IIT Delhi.
            These mentors are carefully selected for their expertise and passion for guiding students to success. After joining our course, a mentor will be assigned to you. They will contact you.
           `,
        },
        {
            title: "What will be the duration of the mentoring session?",
            content: `Mentoring sessions are 40 minutes long and will be taken through Google Meet/zoom meeting/WhatsApp via audio/video call based on the student's convenience.`,
        },
        {
            title: "What can a student ask in the mentoring session?",
            content: `Mentoring sessions are for guidance rather than for teaching subjects.
            Students can ask about the preparation, strategies, study plan, resources, and reference books.`,
        },
        {
           title:"How will the doubts be solved?",
           content:`After joining our course, a doubt solver who is one of the top IITians will be assigned to you. They will contact you through WhatsApp.
           You can ask your doubts on WhatsApp.`
        },
        {
           title:"What type of doubts can a student ask the doubt solver?",
           content:`Doubt-solving sessions are for solving doubts rather than teaching subjects. A student can ask doubts related to Maths, Physics, and Chemistry.`
        },
        {
           title:"When will the mentoring sessions be taken?",
           content:`IITude assures the timing of the mentoring sessions falls after the school/college timings of the students.
           Mostly, the sessions will be taken after 6 p.m. on the weekdays, and students and mentors can mutually decide the time on the weekend.`
        },
        {
           title:"When will the mentor get allotted?",
           content:`After you enroll in the mentoring plan, our team will assign the mentor to you within 24 hours of registering.`
        },
        {
           title:"What is the mode of payment of the fee?",
           content:`Contact Mr.Narendra Gandhi at 8498088512(WhatsApp) and consult him for payment details.`
        },

    ],
};

const styles = {
        bgColor: 'rgba(0,0,0,0.5)',
        titleTextColor: "white",
        rowTitleColor: "white",
        rowContentColor: 'white',
        arrowColor: "green",
        width: '80vw',
        padding: '2rem', // Adjust the padding value as needed
        fontSize: '2.5rem', // Increase the font size to your desired value
};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
};

export default function FAQ (){

    return (
        <div className={classes.mainFAQ}>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
}